<script setup>
import {ref } from 'vue'

const props = defineProps({})

const activeIndex = ref(0);

function active(index) {
    return activeIndex.value === index
}

function slideTo(index) {
    activeIndex.value = index
}
</script>

<template>
    <slot
        name="default"
        v-bind="{ activeIndex, active, slideTo }"
    />
</template>

<style lang="scss">
</style>
