import { gsap } from "@/vendor/gsap";
import { ScrollTrigger } from "@/vendor/gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const SCRUB = true;

export const init = () => {
    const pinnedMediaSections = gsap.utils.toArray(".section-pinned-media");

    pinnedMediaSections.forEach(item => {
        const contentAreas = gsap.utils.toArray(
            item.querySelectorAll(".section-pinned-media__content-area")
        );

        contentAreas.forEach((contentArea, index) => {
            const mediaArea = contentArea.nextSibling;
            const textElement = contentArea.querySelector(".text");

            gsap.fromTo(
                contentArea,
                {
                    opacity: 0,
                    scale: 0.8
                },
                {
                    scrollTrigger: {
                        trigger: textElement,
                        scrub: SCRUB,
                        start: "top bottom",
                        end: "bottom center",
                        invalidateOnRefresh: true,
                    },
                    opacity: 1,
                    scale: 1,
                    duration: 1.25
                }
            );

            gsap.set(mediaArea, { gridRow: 1, autoAlpha: 0 });

            gsap.fromTo(
                mediaArea,
                {
                    autoAlpha: 0
                },
                {
                    scrollTrigger: {
                        trigger: textElement,
                        scrub: SCRUB,
                        start: "top bottom",
                        end: "bottom center",
                        invalidateOnRefresh: true,
                        toggleActions: "play complete reverse reset"
                    },

                    autoAlpha: 1,
                    duration: 1.25
                }
            );

            // gsap.to(mediaArea, {
            //     scrollTrigger: {
            //         trigger: contentArea,
            //         scrub: SCRUB,
            //         start: "bottom center",
            //         end: "bottom top",
            //         invalidateOnRefresh: true,
            //         // markers: true,
            //         toggleActions: "play complete reverse reset"
            //     },
            //     autoAlpha: 0,
            //     duration: 1.25
            // });
        });

    });
};

export default init;
