<script setup>
import {ref } from 'vue'

const shown = ref(false);

const openEmbed = () => { shown.value = true; };
const closeEmbed = () => { shown.value = false; };
</script>

<template>
    <slot name="default" v-bind="{ shown, openEmbed, closeEmbed }" />
</template>

<style lang="scss">
</style>
