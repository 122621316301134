import "./scss/global.scss";
// import "swiper/css";
import { register } from "swiper/element/bundle";

import { createApp } from "vue";
import { useBreakpoints } from "@vueuse/core";
import { OnClickOutside } from "@vueuse/components";
// import { ExpandTransition, FadeTransition } from "@bam/transitions";
import { ScrollTrigger } from "@/vendor/gsap/ScrollTrigger";

import * as ANIMATIONS from './animations';

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept();
}

const resizeObserver = new ResizeObserver(entries => {
    window.setTimeout(() => {
        ScrollTrigger.refresh();
    }, 0)
});

resizeObserver.observe(document.body)

Promise
    .all(Array.from(document.images)
    .filter(img => !img.complete)
    .map(img => new Promise(resolve => { img.onload = img.onerror = resolve; })))
    .then(() => {
        window.setTimeout(() => {
            ScrollTrigger.refresh();
        }, 0)
    });



const app = createApp({
    data: () => {
        return {
            // globalSwiperModules: [Pagination, Parallax],
        };
    },

    mounted() {
        register();

        ANIMATIONS.initMedia();
        ANIMATIONS.initPinnedMedia();
    },
});

// const bps = JSON.parse(
//     getComputedStyle(document.body).getPropertyValue("--bam-breakpoints")
// );
// const breakpoints = useBreakpoints(bps);
// app.config.globalProperties.$breakpoints = breakpoints;

const components = import.meta.glob("./components/**/*.vue", { eager: true });
Object.entries(components).forEach(([path, definition]) => {
    const componentName = path
        .split("/")
        .pop()
        .replace(/\.\w+$/, "");
    app.component(componentName, definition.default);
});

// app.component("expand-transition", ExpandTransition);
// app.component("fade-transition", FadeTransition);
app.component("on-click-outside", OnClickOutside);

app.mount("#app");
