<script setup>
import Swiper from "swiper";
import { ref, onMounted, toRaw, unref } from "vue";

const swiperRef = ref();
const swiper = ref();
const realIndex = ref(0)

const swiperParams = {
    init: true,
    slidesPerView: 2.5,
    rewind: true,
    speed: 500,
    spaceBetween: 20,
    grabCursor: true,
    autoHeight: false,
    breakpoints: {
        0: {
            slidesPerView: 1.1,
        },
        500: {
            slidesPerView: 1.5,
        },
        940: {
            slidesPerView: 2.5,
        }
    },
    on: {
        slideChange: (swiper) => {
            realIndex.value = swiper.realIndex
        },
        init: (swiper) => {
            realIndex.value = swiper.realIndex
        }
    }
};

onMounted(() => {
    swiper.value = new Swiper(swiperRef.value, swiperParams);
});

function slideTo(index) {
    const swiperInstance = toRaw(unref(swiper));
    if (!swiperInstance) return;

    swiperInstance.slideTo(index)
}

function slidePrev() {
    const swiperInstance = toRaw(unref(swiper));
    if (!swiperInstance) return;

    swiperInstance.slidePrev()
}

function slideNext() {
    const swiperInstance = toRaw(unref(swiper));
    if (!swiperInstance) return;

    swiperInstance.slideNext()
}

function isActive(index) {
    return realIndex.value === index;
}
</script>

<template>
    <div class="section-case-slider__content">
        <div class="section-case-slider__main">
            <div class="swiper" ref="swiperRef">
                <div class="swiper-wrapper">
                    <slot name="default" />
                </div>
            </div>
        </div>
    </div>
    <slot
        v-bind="{ slidePrev, slideNext, slideTo, isActive }"
        name="rest"
    />
</template>

<style lang="scss"></style>
