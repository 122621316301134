<script setup>
import { ref } from 'vue'
import { vOnClickOutside } from '@vueuse/components'

const isOpen = ref(false)

function open()   { isOpen.value = true }
function close()  { isOpen.value = false }
function toggle() { isOpen.value = !isOpen.value }
</script>

<template>
    <div
        v-on-click-outside="close"
        :class="['contact-dropdown', { 'contact-dropdown--open': isOpen }]"
    >
        <div
            class="contact-dropdown__header"
            @click="toggle"
        >
            <slot name="header" v-bind="{ open, close, toggle, isOpen }" />
        </div>

        <div class="contact-dropdown__content">
            <transition name="drop-in">
                <ul
                    v-if="isOpen"
                    class="contact-dropdown__options"
                    @click="close"
                >
                    <slot v-bind="{ open, close, toggle, isOpen }" />
                </ul>
            </transition>
        </div>


    </div>


</template>

<style lang="scss">
@use "@/scss/framework" as *;

.contact-dropdown {
    :root {
        @include v((
            contact-dropdown-color: v(color-white-100),
            contact-dropdown-color--hover: v(color-white-100),
            contact-dropdown-background-color: v(color-royal-blue-100),
            contact-dropdown-background-color--hover: v(color-royal-blue-60),
        ));
    }

    @include v((
        _color: v(fab-button-color),
        _color--hover: v(fab-button-color--hover),
        _background-color: v(fab-button-background-color),
        _background-color--hover: v(fab-button-background-color--hover),
        _transition: v(duration-slow) v(easing-default),
    ));

    display: inline-flex;
    flex-flow: column;
    align-items: flex-start;
    gap: 0;
    position: relative;
    padding: 0;
    font: v(font-fab-button);

    &__header {
        position: relative;
        cursor: pointer;
        transition:
            color v(_transition),
            background-color v(_transition);
        text-decoration: none;
        text-transform: uppercase;
        color: v(_color);
        background-color: v(_background-color);
        z-index: 1;

        .contact-dropdown:not(.contact-dropdown--open) &:hover {
            @media (hover: hover) and (pointer: fine) {
                color: v(_color--hover);
                background-color: v(_background-color--hover);
            }
        }
    }

    &__content {
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
    }

    &__options {
        position: relative;
        list-style: none;
        margin: calc(-1 * v(_border-radius)) 0 0;
        padding: inherit;
        background-color: v(_background-color);
        z-index: 2;

        display: flex;
        flex-flow: column;
        gap: 0;
        overflow: clip;

        a {
            color: inherit;
            text-decoration: none;
        }

        > * {

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    color: v(_color--hover);
                    background-color: v(_background-color--hover);
                }
            }
        }
    }

    &--open {
        .contact-dropdown__header {
            cursor: default;
            background-color: v(_background-color);
            overflow: clip;
            color: v(color-blue-zodiac-100, false, true);

            * { color: inherit; }
        }
    }
}

.drop-in-enter-active {
    transform-origin: top;
    animation: drop-in v(duration-slow) v(easing-decelerate) forwards;
}

.drop-in-leave-active {
    transform-origin: top;
    animation: drop-out v(duration-slow) v(easing-decelerate) forwards;
}

@keyframes drop-in {
    0% { opacity: 0; transform: rotateX(90deg); }
    20% { opacity: 1; }
    100% { opacity: 1; transform: rotateX(0deg); }
}

@keyframes drop-out {
    0% { opacity: 1; transform: rotateX(0deg); }
    80% { opacity: 1; }
    100% { opacity: 0; transform: rotateX(90deg); }
}
</style>
