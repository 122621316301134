import { gsap } from "@/vendor/gsap";
import { ScrollTrigger } from "@/vendor/gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

window.gsap = gsap;

export const init = () => {

    const sections = document.querySelectorAll('.section-media');

    sections.forEach(section => {
        const captionText = section.querySelectorAll('.section-media__background-caption-text');

        captionText.forEach((media, index) => {

            gsap.fromTo(media,
                {
                    x: 100 + '%',
                },
                {
                    x: (-1 * 100) + '%',
                    ease: "none",
                    scrollTrigger: {
                        trigger: section,
                        start: "top bottom",
                        end: "bottom top",
                        scrub: true
                    }
            })
        })
    })

}

export default init;
